import React, { FunctionComponent, useState, ChangeEventHandler, KeyboardEvent, ChangeEvent } from "react";
import { FundsTableHolder } from "styles/funds/MasterBuylist.styled";
import { Accordion } from "components/party/accordion/Accordion";
import { 
    TemporaryMasterInstrumentsFundManagerType,
    TemporaryFundsType,
    TemporaryFigiMapType
 } from "types/funds";

 import {  
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable
} from "styles/funds/FundManagerT360.styled";

const SelectedFmManagedFundsTable : FunctionComponent<{
    dataToDisplay: TemporaryMasterInstrumentsFundManagerType[];
    selectedfm: {index: number; obj: TemporaryMasterInstrumentsFundManagerType } | undefined;
    setSelectedFund: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFundsType} | undefined>>;
    setSelectedFigi: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFigiMapType} | undefined>>;
    setFmAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fmAccordionOpen: boolean;
    fmView: boolean;
}> =  ({
    dataToDisplay,
    selectedfm,
    setSelectedFund,
    setSelectedFigi,
    setFmAccordionOpen,
    fmAccordionOpen,
    fmView
}) => {  

    const handleTableClick = (fmIndex:number, fundIndex: number) => {
        setSelectedFund({index: fundIndex, obj: dataToDisplay[fmIndex].funds[fundIndex]});
        if(dataToDisplay[fmIndex].funds[fundIndex] && dataToDisplay[fmIndex].funds[fundIndex].figi_map.length > 0) {
            setSelectedFigi({index: 0, obj: dataToDisplay[fmIndex].funds[fundIndex].figi_map[0]});
        } else {
            setSelectedFigi(undefined)
        }
        setFmAccordionOpen(!fmAccordionOpen);
    }

    return (
        <FundsTableHolder>
            <Accordion key={"fund-table-accordion"} title={fmView ? "Managed Funds": "SS&C Hubwise - Fund List"} small noTopBorder>
                <WrapTable>    
                    <RespTable>
                        <RespTableHeader >
                            <RespTableRow>
                                <TableHeaderCell>Fund</TableHeaderCell> 
                                <TableHeaderCell>ISIN</TableHeaderCell> 
                                <TableHeaderCell>SEDOL</TableHeaderCell> 
                                <TableHeaderCell>FIGI</TableHeaderCell> 
                                <TableHeaderCell>Ringfenced*</TableHeaderCell> 
                                {/* hardcoded for demo on 27th Sep 2024 - TODO: to be removed */}
                                    <TableHeaderCell>RLB*</TableHeaderCell> 
                                    <TableHeaderCell>Transfer In*</TableHeaderCell> 
                                {/* -- */}
                                { fmView && <TableHeaderCell>Permissioned*</TableHeaderCell> }
                            </RespTableRow>
                        </RespTableHeader>   
                        {!(dataToDisplay?.length === 0 || !dataToDisplay) &&   
                            <RespTableBody>
                                {selectedfm && selectedfm.index && dataToDisplay[selectedfm?.index].funds.map((fund, index) => (
                                    <React.Fragment key={index}>
                                        <RespTableRow  onClick={()=>handleTableClick(selectedfm?.index, index)}>
                                            {fund.figi_map.length > 0 ? <TableBodyCell>{fund.figi_map[0].name}</TableBodyCell> : 
                                            <TableBodyCell>{fund["Instrument Name"]}</TableBodyCell>}
                                            <TableBodyCell> {fund.ISIN}</TableBodyCell>
                                            <TableBodyCell> {fund.Sedol} </TableBodyCell>
                                            {fund.figi_map.length > 0 ?  (
                                                <TableBodyCell>{fund.figi_map[0].figi}</TableBodyCell> 
                                            ) : <TableBodyCell>{"-"}</TableBodyCell> }
                                            <TableBodyCell>{fund.lists.some(item => item["Buylist Code"] === "BLST01" && item["Buylist Description"] === "Standard Buylist") ? "No" : "Yes"}</TableBodyCell> 
                                            {/* hardcoded for demo on 27th Sep 2024 - TODO: to be removed */}
                                                <TableBodyCell>{"No"}</TableBodyCell>
                                                <TableBodyCell>{"No"}</TableBodyCell>
                                            {/* -- */}
                                            {fmView && window.innerWidth > 769 &&<TableBodyCell>{fund.lists.length === 0 ? "No" : "Yes"}</TableBodyCell>}
                                        </RespTableRow>

                                    </React.Fragment>
                                ))}
                            </RespTableBody> 
                        }
                    </RespTable>
                </WrapTable>
            </Accordion>
        </FundsTableHolder>
    )
}

export default SelectedFmManagedFundsTable;