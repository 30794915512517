import React, { FunctionComponent, useState } from "react";
import { Accordion } from "components/party/accordion/Accordion";
import { 
    TemporaryMasterInstrumentsFundManagerType,
    TemporaryFundsType,
    TemporaryFigiMapType
 } from "types/funds";

 import { 
    FormRow,
    AccordionHolder,
    FundManagerInfoHolder,
    SubmitBtnHolder
} from "styles/funds/MasterBuylist.styled";
import { FormLabel, InputWrapper, Input } from "styles/fundDetailsConfirmation/FundDetailsConfirmation.styled";
import SelectedFmManagedFundsTable from "./SelectedFmManagedFundsTable";
import SubmitButton from "components/alertAirtableTesting/SubmitButton";

const SelectedFmInfo : FunctionComponent<{
    dataToDisplay: TemporaryMasterInstrumentsFundManagerType[];
    selectedfm: {index: number; obj: TemporaryMasterInstrumentsFundManagerType } | undefined;
    setSelectedFund: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFundsType} | undefined>>;
    setSelectedFigi: React.Dispatch<React.SetStateAction<{index: number; obj: TemporaryFigiMapType} | undefined>>;
    setFmAccordionOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fmAccordionOpen: boolean;
    setSelectedfm: React.Dispatch<React.SetStateAction<{
        index: number;
        obj: TemporaryMasterInstrumentsFundManagerType;
    } | undefined>>;
    addressDetails: boolean;
    editFM: boolean;
    fmView: boolean;
    setData: React.Dispatch<React.SetStateAction<TemporaryMasterInstrumentsFundManagerType[] | undefined>>;
}> =  ({
    dataToDisplay,
    selectedfm,
    setSelectedFund,
    setSelectedFigi,
    setFmAccordionOpen,
    fmAccordionOpen,
    setSelectedfm,
    addressDetails,
    editFM,
    fmView,
    setData
}) => {  
    const [editEnabled, setEditEnabled]= useState<boolean>(false);
    const handleFmChange = (label: string, value: string | {
            "Line 1": string | null;
            "Line 2": string | null;
            "Line 3": string | null;
            "Line 4": string | null;
            "Line 5": string | null;
            "Post Code": string | null;
            [key: string]: string | null
        } | {
            "Telephone Number": string | null;
            "Email": string | null;
            [key: string]: string | null;
        }) => {
        const newfm= selectedfm?.obj;
        if(newfm && selectedfm) {
            newfm[label]= value;
            setSelectedfm({ index: selectedfm.index, obj: newfm });
        }
    };

    const handleAddressChange= (label: string, value: string) => {
        const newAddress= selectedfm?.obj.Address;
        if (newAddress && selectedfm) {
            newAddress[label]= value;
            handleFmChange("Address", newAddress);
        }
    }

    const handleContactDetailsChange= (label: string, value: string) => {
        const newContacts= selectedfm?.obj["Contact Information"];
        if (newContacts && selectedfm) {
            newContacts[label]= value;
            handleFmChange("Contact Information", newContacts);
        }
    }

    const handleSubmit = () => {
        if(selectedfm) {
            setData(prevState => {
                const updatedState= prevState?.map((fm, fmI) => {
                    if(fmI === selectedfm?.index) {
                        return { ...selectedfm.obj};
                    } else {
                        return fm;
                    }
                })
                return updatedState
            })

        }
        setEditEnabled(false);
    }

    return (
        <AccordionHolder>
                    <Accordion key={"instrument-dashboard-accordion"} title={"Fund Manager"} noTopBorder opened={!!fmAccordionOpen}>
                        <FundManagerInfoHolder>
                            <FormRow>
                                <FormLabel htmlFor={"Provider Name"}>Provider Name</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Provider Name"}
                                        defaultValue={selectedfm?.obj["Provider Name"] || ""}
                                        onChange={(e) => handleFmChange("Provider Name", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>
                                <FormLabel htmlFor={"Provider Code"}>Provider Code</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Provider Code"}
                                        defaultValue={selectedfm?.obj["Provider Code"] || ""}
                                        onChange={(e) => handleFmChange("Provider Code", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormLabel htmlFor={"Status"}>Status</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Provider Code"}
                                        defaultValue={selectedfm ? selectedfm.obj["Status"] : "-"}
                                        onChange={(e) => handleFmChange("Status", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>
                                <FormLabel htmlFor={"Morningstar Code"}>Morningstar Code</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Morningstar Code"}
                                        defaultValue={selectedfm ? selectedfm.obj["Morningstar Code"] : "-"}
                                        onChange={(e) => handleFmChange("Morningstar Code", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormLabel htmlFor={"Provider Type"}>Provider Type</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Provider Type"}
                                        defaultValue={selectedfm ? selectedfm.obj["Provider Type"] : "-"}
                                        onChange={(e) => handleFmChange("Provider Type", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>
                                <FormLabel htmlFor={"External Provider ID"}>External Provider ID</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"External Provider ID"}
                                        defaultValue={selectedfm ? selectedfm.obj["External Provider ID"] : "-"}
                                        onChange={(e) => handleFmChange("External Provider ID", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormLabel htmlFor={"EMX Product ID"}>EMX Product ID</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"EMX Product ID"}
                                        defaultValue={selectedfm ? selectedfm.obj["EMX Product ID"] : "-"}
                                        onChange={(e) => handleFmChange("EMX Product ID", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>
                                <FormLabel htmlFor={"EMX Provider ID"}>EMX Provider ID</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"EMX Provider ID"}
                                        defaultValue={selectedfm ? selectedfm.obj["EMX Provider ID"] : "-"}
                                        onChange={(e) => handleFmChange("EMX Provider ID", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormLabel htmlFor={"Routing Code 1"}>Routing Code 1</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Routing Code 1"}
                                        defaultValue={selectedfm ? selectedfm.obj["Routing Code 1"] ? selectedfm.obj["Routing Code 1"]  : "-" : "-"}
                                        onChange={(e) => handleFmChange("Routing Code 1", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>
                                <FormLabel htmlFor={"Routing Code 2"}>Routing Code 2</FormLabel>
                                <InputWrapper>
                                    <Input
                                        type="text"
                                        id={"Routing Code 2"}
                                        defaultValue={selectedfm ? selectedfm.obj["Routing Code 2"] ? selectedfm.obj["Routing Code 2"] : "-" : "-"}
                                        onChange={(e) => handleFmChange("Routing Code 2", e.target.value)}
                                        disabled= {!editEnabled}
                                    />
                                </InputWrapper>

                            </FormRow>
                            {addressDetails &&
                                <>
                                    <FormRow>
                                        <FormLabel htmlFor={"Address Line 1"}>Address Line 1</FormLabel>
                                        <InputWrapper>
                                            <Input
                                                type="text"
                                                id={"Address Line 1"}
                                                defaultValue={selectedfm ? selectedfm.obj["Address"]["Line 1"] ? selectedfm.obj["Address"]["Line 1"] : "-" : "" }
                                                onChange={(e) => handleAddressChange("Line 1", e.target.value)}
                                                disabled= {!editEnabled}
                                            />
                                        </InputWrapper>
                                        <FormLabel htmlFor={"Address Line 2"}>Address Line 1</FormLabel>
                                        <InputWrapper>
                                            <Input
                                                type="text"
                                                id={"Address Line 2"}
                                                defaultValue={selectedfm ? selectedfm.obj["Address"]["Line 2"] ? selectedfm.obj["Address"]["Line 2"] : "-" : "" }
                                                onChange={(e) => handleAddressChange("Line 2", e.target.value)}
                                                disabled= {!editEnabled}
                                            />
                                        </InputWrapper>
                                    </FormRow>
                                    <FormRow>
                                        <FormLabel htmlFor={"Email"}>Email</FormLabel>
                                        <InputWrapper>
                                            <Input
                                                type="text"
                                                id={"Email"}
                                                defaultValue={selectedfm ? selectedfm.obj["Contact Information"].Email ? selectedfm.obj["Contact Information"].Email : "-" : "-"}
                                                onChange={(e) => handleContactDetailsChange("Email", e.target.value)}
                                                disabled= {!editEnabled}
                                            />
                                        </InputWrapper>
                                        <FormLabel htmlFor={"Telephone Number"}>Telephone Number</FormLabel>
                                        <InputWrapper>
                                            <Input
                                                type="text"
                                                id={"Telephone Number"}
                                                defaultValue={selectedfm ? selectedfm.obj["Contact Information"]["Telephone Number"] ? selectedfm.obj["Contact Information"]["Telephone Number"] : "-" : "-"}
                                                onChange={(e) => handleContactDetailsChange("Telephone Number", e.target.value)}
                                                disabled= {!editEnabled}
                                            />
                                        </InputWrapper>
                                    </FormRow>
                                </>
                            }
                            {editFM &&                            
                            <SubmitBtnHolder>
                                <SubmitButton
                                    onClick={()=>setEditEnabled(!editEnabled)}
                                    text={"Edit"}
                                    type={"submit"}
                                    disabled={editEnabled}
                                />
                                <SubmitButton
                                    onClick={handleSubmit}
                                    text={"Submit"}
                                    type={"submit"}
                                    disabled={!editEnabled}
                                />
                            </SubmitBtnHolder>
                            }
                            <SelectedFmManagedFundsTable
                                dataToDisplay={dataToDisplay}
                                selectedfm={selectedfm}
                                setSelectedFund={setSelectedFund}
                                setSelectedFigi={setSelectedFigi}
                                setFmAccordionOpen={setFmAccordionOpen}
                                fmAccordionOpen={fmAccordionOpen}
                                fmView={fmView}
                            />
                            
                        </FundManagerInfoHolder>
                        
                    </Accordion>
                </AccordionHolder>
    )
}

export default SelectedFmInfo;