import { HubwiseDashboardMenuItem } from "types";

const dashboardIcon = `${process.env.PUBLIC_URL}/assets/hubwise-asset-onboarding-icon.svg`;
const fundsIconT360 = `${process.env.PUBLIC_URL}/assets/docImage.svg`;
const entityIcon = `${process.env.PUBLIC_URL}/assets/pending.svg`;
const instrumentsManagerIcon= `${process.env.PUBLIC_URL}/assets/hubwise-instruments-manager.svg`;
const fundManagerIcon= `${process.env.PUBLIC_URL}/assets/hubwise-fund-manager-icon.svg`;
const clientManagerIcon= `${process.env.PUBLIC_URL}/assets/hubwise-client-manager-icon.svg`;
const reportsIcon = `${process.env.PUBLIC_URL}/assets/report.svg`;
const userGuidesIcon = `${process.env.PUBLIC_URL}/assets/hubwise-user-guides-icon.svg`;
const hubwiseMyViewIcon = `${process.env.PUBLIC_URL}/assets/hubwise-my-view-icon.svg`;
const crownIcon = `${process.env.PUBLIC_URL}/assets/crown.svg`;
const ssiIcon = `${process.env.PUBLIC_URL}/assets/SSIIcon.svg`;

export const hubwiseSideBarItemsDetails = ({
   pathname
  }: {
   pathname: string
  }): HubwiseDashboardMenuItem[] =>{
    return [
        // {
        //     text: "My View",
        //     icon: hubwiseMyViewIcon,
        //     link: "my-view",
        //     isUserAllowed: pathname.includes("investments")
        // },
        {
            text: "Ask ALICE",
            icon: crownIcon,
            link: "alice-requests",
            isUserAllowed: pathname.includes("investments")
        },
        {
            text: "Asset Onboarding",
            icon: dashboardIcon,
            link: "onboarding",
            isUserAllowed: pathname.includes("investments")
        },
        {
            text: "Instrument Manager",
            icon: instrumentsManagerIcon,
            link: "buylist",
            isUserAllowed: pathname.includes("investments")
        },
        // {
        //     text: "SSI",
        //     icon: ssiIcon,
        //     link: "ssi",
        //     isUserAllowed: true
        // },
        {
            text: "Fund Manager",
            icon: fundManagerIcon,
            link: "fund-manager",
            isUserAllowed: pathname.includes("investments")
        },
        // {
        //     text: "Active CCIDs",
        //     icon: xxxxxIcon,
        //     link: "active-ccids",
        //     isUserAllowed: true
        // },
        {
            text: "Client Requests",
            icon: clientManagerIcon,
            link: "c-manager",
            isUserAllowed: pathname.includes("investments")
        },
        {
            text: "My Reports",
            icon: reportsIcon,
            link: "reports",
            isUserAllowed: pathname.includes("investments")
        },
        {
            text: "User Guides",
            icon: userGuidesIcon,
            link: "user-guides",
            isUserAllowed: true
        },
        // {
        //     text: "Legal",
        //     icon: legalIcon,
        //     link: "legal",
        //     isUserAllowed: true
        // },
        {
            text: "Active Requests",
            icon: entityIcon,
            link: "active-requests",
            isUserAllowed: pathname.includes("instruments")
        },
        {
            text: "Active Funds",
            icon: fundsIconT360,
            link: "active-funds-clients",
            isUserAllowed: pathname.includes("instruments")
        },
    ];
}

export const FundPartyManagerSideBarDetails = ({
    pathname
  }: {
    pathname: string
  }): HubwiseDashboardMenuItem[] => {
   return hubwiseSideBarItemsDetails({ pathname })
  };