import { FunctionComponent } from "react";
import {
    BottomContentContainer,
    FiltersContainer,
    SearchWrapper,
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable
} from "styles/funds/Onboarding.styled";
import useFilters from "hooks/useFilter";
import { Search } from "../../filter/Search";
import { format } from "date-fns";
import { PATH } from "../../../constants/index";
import { ClientRequestTemplateType } from "types/party";
import { TableLink, EmptySpan, SpanWrapper, TableDate, TableTime } from "styles/admin/AdminTable.styled";

const ClientRequestsOnboardingTable: FunctionComponent<{data: ClientRequestTemplateType[]}> =  ({data}) => {

    const { filteredData, setFilter } = useFilters(data);
    const setData = (str: string) => {
        setFilter({ search: str });
    };
    
    return (
        <BottomContentContainer>
            <FiltersContainer>
                <SearchWrapper>
                    <Search setData={setData} />
                </SearchWrapper>
            </FiltersContainer>
            <WrapTable>
                <RespTable>
                    <RespTableHeader>
                        <RespTableRow>
                            <TableHeaderCell>Client Channel ID</TableHeaderCell> 
                            <TableHeaderCell>Client Channel Name</TableHeaderCell>
                            <TableHeaderCell>xxxxxx</TableHeaderCell> 
                            <TableHeaderCell>Project Team</TableHeaderCell> 
                            <TableHeaderCell>Last Updated</TableHeaderCell> 
                        </RespTableRow>
                    </RespTableHeader>
                    <RespTableBody>
                        {filteredData.map((ccid,index) => (
                            <RespTableRow key={index} data-testid="row">
                                { window.innerWidth > 500 && 
                                    <TableBodyCell>
                                        <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >
                                            {ccid.id}
                                        </TableLink>
                                    </TableBodyCell> 
                                }
                                <TableBodyCell>
                                    <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >
                                        {ccid["Party Legal Name"]}
                                    </TableLink>
                                </TableBodyCell>                                
                                <TableBodyCell>
                                    <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >
                                        {ccid["Legal Name"]}
                                    </TableLink>
                                </TableBodyCell> 
                                <TableBodyCell>
                                    <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >
                                        {ccid["Legal Name"]} 
                                    </TableLink>
                                </TableBodyCell> 
                                <TableBodyCell>
                                <TableBodyCell>
                                    <TableLink to={`../../../${PATH.CLIENT_REQUEST_TEMPLATE}/${ccid.id}`} >

                                        
                                        {format(new Date(ccid["Last Update Datetime"] ? ccid["Last Update Datetime"] : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)"), "dd MMM yyy")} {"  "}
                                        
                                        {format(new Date(new Date(ccid["Last Update Datetime"] ? ccid["Last Update Datetime"] : "Jan 3 2024 12:46:55 GMT+0200 (Eastern European Standard Time)")), "HH:mm")}    
                                        

                                    </TableLink>
                                </TableBodyCell>
                                </TableBodyCell> 
                            </RespTableRow> 
                        ))}
                        
                    </RespTableBody>
                </RespTable>
            </WrapTable>
        </BottomContentContainer>
    )
};

export default ClientRequestsOnboardingTable;