import { FunctionComponent, useState, ChangeEventHandler, KeyboardEvent, useEffect } from "react";
import { 
    AddWidgetsButton,
    BtnHolder,
    HeaderAreaContainer,
    ModalContainer,
    ModalContent,
    NavContainer,
    NavItem,
    Title,
    MainContentContiner,
    SideBarNav,
    SideBarNavItem,
    SubMenuContainer,
    AddToMyViewBtnHolder 
} from "styles/funds/AddWidgets.styled";
import { 
    DarkLayer,
    CloseIcon,
    closeIcon,
} from "styles/crownAlert/CrownAlert.styled";
import { WIDGET_SELECTION_MAIN_TABS, 
    WIDGET_SELECTION_MAIN_TAB, 
    ONBOARDING_WIDGETS_TAB_ITEMS, 
    ONBOARDING_WIDGETS_TAB_ITEM,
    REPORTS_WIDGETS_TAB_ITEMS,
    REPORTS_WIDGETS_TAB_ITEM 
} from "constants/hubwiseDashboard";
import { getItem, setItem } from "utils/localStorage";
import { useAppData } from "hooks/useAppData";
import { FundType, TemporaryFigiMapType, TemporaryMasterInstrumentsFundManagerType, TemporaryFundsType } from "types/funds";
import { 
    InstrumentSuggestions 
} from "styles/funds/MasterBuylist.styled";
import { FilterInput, FilterInputWrapper, FilterIcon } from "styles/filter/Search.styled";
import { SEARCH_CLASS_TYPE } from "constants/filter";
import { SuggestionItem, SuggestionItemName } from "styles/searchSuggestion/SearchSuggestionItem.styled";
import { 
    Loader,
    LoaderBar, 
    SuggestionNotFound
} from "styles/SearchSuggestion.styled";
import {
    FUNDS_DATA_REPOSITORY_BUCKET_NAME,
    CRTS_CCIDS_BUYLISTS_PATH,
    HUBWISE_FUNDS_DETAILS_PATH
} from "config/googleCloudConfig";
import { callGCFunctionWIthAccessToken } from "services/callGCFunction";
import { GET_OBJECT_FROM_STORAGE } from "config/googleCloudFunctionsConfig";
import { CrtType } from "types/party";
import { getAccessToken } from "services/auth0/auth0";

const AddWidgetComponent: FunctionComponent <{
    userPreferences: string[];
    itemsToMonitor: FundType[];
    setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    userPreferences,
    itemsToMonitor,
    setUpdated
}) => {
    const [isActive, setIsActive]= useState<boolean>(false);
    const [query, setQuery] = useState<string|undefined>("");
    const [suggestions, setSuggestions] = useState<FundType[]>([]);
    const [somethingSearched, setSomethingSearched]= useState<boolean>(false);
    const [ loading, setLoading ]= useState<boolean>(false);
    const [allFunds, setAllFunds] = useState<FundType[]>([]);
    const [mainTab, setMainTab] = useState<WIDGET_SELECTION_MAIN_TAB>(WIDGET_SELECTION_MAIN_TAB.ONBOARDING);
    const [onboardingTab, setOnboardingTab]= useState<ONBOARDING_WIDGETS_TAB_ITEM | undefined>();
    const [reportsTab, setReportsTab]= useState<REPORTS_WIDGETS_TAB_ITEM | undefined>();
    const [isMonitorItem, setIsMonitorItem]= useState<boolean>(false);
    const [selectedItemToMonitor, setSelectedItemToMonitor]= useState<FundType>();
    const [selectedItem, setSelectedItem]= useState<ONBOARDING_WIDGETS_TAB_ITEM | REPORTS_WIDGETS_TAB_ITEM | undefined>();
    const { user }= useAppData();
    const token = getAccessToken();

    const handleClick = () => {
        setIsActive(true);
    }
    const handleCloseModal = () => {
        setIsActive(false);
        setMainTab(WIDGET_SELECTION_MAIN_TAB.ONBOARDING);
        setOnboardingTab(undefined);
        setReportsTab(undefined);
        setSelectedItem(undefined);
        setIsMonitorItem(false);
        setSelectedItemToMonitor(undefined);
        setSomethingSearched(false);
        setQuery("");
        setSuggestions([]);
    }

    useEffect(()=>{
        // get CRT information
        if(token) {
            const crtFile= "crt_0000001.json";
            const payloadForCrtFile= {
                "bucketName": FUNDS_DATA_REPOSITORY_BUCKET_NAME,
                "fileName": crtFile,
                "filePath": `${CRTS_CCIDS_BUYLISTS_PATH}`
            }

            callGCFunctionWIthAccessToken(token, GET_OBJECT_FROM_STORAGE, payloadForCrtFile, "test", "test")
            .then((arrayBuffer) => JSON.parse(new TextDecoder().decode(arrayBuffer)))
            .then((data: CrtType)=>{
                if(data) {
                    if(data["Instruments Manager List"] && data["Instruments Manager List"].length > 0) {
                        data["Instruments Manager List"].map((fundManager) => {
                        const newObj: {[key: string]: {"Record Id": string; "ISIN": string; "SEDOL": string}[]}= {[fundManager.PID]:[] }
                        if(fundManager["Instruments List"] && fundManager["Instruments List"].length > 0) {
                            fundManager["Instruments List"].map((fund) => {
                            const newFundsArray= allFunds;
                            newFundsArray.push(fund);
                            setAllFunds(newFundsArray);
                            newObj[fundManager.PID].push({"Record Id": fund["Record Id"], "ISIN": fund.ISIN, "SEDOL": fund.SEDOL});
                            })
                        }
                    })
                } }
            }) 
        }
    },[token])

    const handleAddWidget = (selectedItem: string | undefined) => {
        handleCloseModal();

        if (selectedItem) {
            let found = false;

            if (userPreferences.length > 0 ) {
                userPreferences.map((item) => {
                    if(item === selectedItem) {
                        found= true;
                    }
                })
            }
            const newArr= userPreferences;
            const newItemsToMonitor: FundType[] | undefined= itemsToMonitor;
            const globalPreferences= getItem("userPreferences", []);
            let emailFound= false;

            if (!found) {
                newArr?.push(selectedItem);
                if(selectedItemToMonitor) {
                    newItemsToMonitor.push(selectedItemToMonitor);
                }
                let emailFound: boolean= false;
                if(globalPreferences.length > 0) {
                    globalPreferences.map((item: {email: string, preferences: string[], itemsToMonitor: FundType[]}) => {
                        if(item.email === user?.email) {
                            item.preferences = newArr;
                            item.itemsToMonitor= newItemsToMonitor;
                            setItem("userPreferences", globalPreferences);
                            emailFound= true;
                        }
                    })
                }
                if(!emailFound) {
                    setItem("userPreferences", [...globalPreferences, {email: user?.email, preferences: newArr, itemsToMonitor: newItemsToMonitor}]);
                }
                setUpdated(true);
            } else {
                if (selectedItemToMonitor) {
                    const exists= itemsToMonitor.filter(fund => fund["Record Id"] === selectedItemToMonitor["Record Id"])
                    if (exists) {
                        newItemsToMonitor.push(selectedItemToMonitor);
                        if(globalPreferences.length > 0) {
                            globalPreferences.map((item: {email: string, preferences: string[], itemsToMonitor: FundType[]}) => {
                                if(item.email === user?.email) {
                                    item.preferences = newArr;
                                    item.itemsToMonitor= newItemsToMonitor;
                                    setItem("userPreferences", globalPreferences);
                                    emailFound= true;
                                }
                            })
                        }
                        if(!emailFound) {
                            setItem("userPreferences", [...globalPreferences, {email: user?.email, preferences: newArr, itemsToMonitor: newItemsToMonitor}]);
                        }
                        setUpdated(true);
                    } else {
                        alert("Asset already monitored")
                    }
                    
                } else {
                    console.log("Item is already on screen");
                }
                
            }
        }
    }

    const filterData = (data: FundType[], value: string): Promise<FundType[]> => {
        return new Promise((resolve) => {
            setTimeout(()=>{
                const result: FundType[]=[];                
                let matchCount: number= 0;
                const maxMatches= 10;
                data.forEach((fund: FundType) => {
                    if(matchCount >= maxMatches) return;
                    
                    if (fund.ISIN && fund.ISIN.toLowerCase().includes(value) ||
                    fund.SEDOL && fund.SEDOL.toLowerCase().includes(value) ||
                    fund["Fund Full Name"] && fund["Fund Full Name"].toLowerCase().includes(value) ||
                    fund.FIGI && fund.FIGI.toLowerCase().includes(value)
                        ) {
                        result.push(fund);
                        matchCount++;
                        return;
                    }

                    if(matchCount >= maxMatches) return;

                })
                resolve(result);
            }, 800);
        });
    }

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setQuery(event.target.value);
    };

    const handleKeyUp = async (event: KeyboardEvent<HTMLInputElement>) => {

        if (event.key === "Enter" && query && query.trim() !== "") {
            setSomethingSearched(true);
            setLoading(true);
            const result = await filterData(allFunds, query.toLowerCase());
            setSuggestions(result);
            setLoading(false);
        } else if (query && query.length === 0) {
            setSuggestions([]);
            setSomethingSearched(false);
            setLoading(false);
        }
    }; 

    const handleSearchSuggestionClick = (fund: FundType) => {
        setSelectedItemToMonitor(fund);
        setSuggestions([]);
        setQuery(fund["Fund Full Name"]);
        setSomethingSearched(false);
    }

    return (
        <>
             <HeaderAreaContainer>
                <BtnHolder>
                    <AddWidgetsButton onClick={handleClick}>Add Widget</AddWidgetsButton> 
                </BtnHolder>
            </HeaderAreaContainer>
            {isActive && 
                <>
                    <DarkLayer />
                    <ModalContainer>
                        <CloseIcon iconURL={closeIcon} onClick={handleCloseModal} />
                        <ModalContent>
                            <Title>Add a Widget</Title>
                            <NavContainer>
                                {WIDGET_SELECTION_MAIN_TABS.map((tabl)=>(
                                    <NavItem 
                                        key={tabl} 
                                        isActive={mainTab === tabl} 
                                        onClick={()=>{setMainTab(tabl); setSelectedItem(undefined); setOnboardingTab(undefined); setReportsTab(undefined)}}

                                    >
                                        {tabl}
                                    </NavItem>
                                ))}
                            </NavContainer>
                            <MainContentContiner>
                                <SideBarNav>
                                    {mainTab === WIDGET_SELECTION_MAIN_TAB.ONBOARDING && 
                                        <>
                                            {ONBOARDING_WIDGETS_TAB_ITEMS.map((tabl, index)=>(
                                                <SideBarNavItem 
                                                    key={tabl}
                                                    isActive={onboardingTab === tabl}
                                                    borderBottom={index !== ONBOARDING_WIDGETS_TAB_ITEMS.length - 1}
                                                    onClick={()=> {setOnboardingTab(tabl); setSelectedItem(tabl)}}
                                                >
                                                    {tabl}
                                                </SideBarNavItem>
                                            ))}
                                        </>
                                    }
                                    {mainTab === WIDGET_SELECTION_MAIN_TAB.REPORTS && 
                                        <>  
                                            {REPORTS_WIDGETS_TAB_ITEMS.map((tabl, index)=>(
                                                <SideBarNavItem 
                                                    key={tabl}
                                                    isActive={reportsTab === tabl}
                                                    borderBottom={index !== REPORTS_WIDGETS_TAB_ITEMS.length - 1}
                                                    onClick={()=>{setReportsTab(tabl); setSelectedItem(tabl); tabl === "Monitor Item" && setIsMonitorItem(true)}}
                                                >
                                                    {tabl}
                                                </SideBarNavItem>
                                            ))}
                                        </>
                                    }
                                </SideBarNav> 
                                <SubMenuContainer>
                                    <AddToMyViewBtnHolder>
                                        <AddWidgetsButton onClick={()=>handleAddWidget(selectedItem)} disabled={!selectedItem && (!isMonitorItem ? selectedItemToMonitor ? false : true : false)}>Add to My View</AddWidgetsButton> 
                                        {isMonitorItem && (
                                            <InstrumentSuggestions noMargin={true}>
                                                <FilterInputWrapper classType={SEARCH_CLASS_TYPE.DEFAULT}>
                                                    <FilterIcon icon="Search" />
                                                    <FilterInput
                                                        value={query || ""}
                                                        onChange={handleChange}
                                                        placeholder="Search for a fund..."
                                                        aria-label="input"
                                                        classType={SEARCH_CLASS_TYPE.DEFAULT}
                                                        onKeyUp={ handleKeyUp }
                                                    />
                                                </FilterInputWrapper>
                                                {suggestions.length > 0 && !loading && (                
                                                    suggestions.map((suggestion, fmIndex) => (
                                                        <div key={fmIndex}>
                                                            <SuggestionItem key={fmIndex} onClick={()=>handleSearchSuggestionClick(suggestion)}>
                                                                <SuggestionItemName>{suggestion["Fund Full Name"]}</SuggestionItemName>
                                                            </SuggestionItem>
                                                        </div>
                                                    ))                
                                                )}
                                                {suggestions.length == 0 && !loading && somethingSearched && (
                                                    <SuggestionNotFound data-testid="suggestion-not-found">
                                                        Nothing found, try a different search
                                                    </SuggestionNotFound>
                                                )}
                                                {loading &&
                                                    <Loader data-testid="loader">
                                                        <LoaderBar />
                                                    </Loader>
                                                }
                                            </InstrumentSuggestions>
                                        )}
                                    </AddToMyViewBtnHolder>
                                </SubMenuContainer>
                            </MainContentContiner>
                        </ModalContent>
                    </ModalContainer>
                </>
            }
        </>
    )
};

export default AddWidgetComponent