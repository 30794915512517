import React, { FunctionComponent, useState, ChangeEventHandler, KeyboardEvent, useEffect } from "react";
import { TemporaryMasterInstrumentsFundManagerType, TemporaryFundsType } from "types/funds";
import NoData from "components/noData/NoData";
import { 
    InstrumentSuggestions,
    SelectedListHolder,
    CloseIcon,
    CloseIconHolder, 
    FundsTableContainer,
    DashboardSelectedList,
    StyledP,
    StyledPValue,
    DashboardItem
} from "styles/funds/MasterBuylist.styled";
import { 
    closeIcon,
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable
} from "styles/funds/FundManagerT360.styled";
import { 
    Loader,
    LoaderBar, 
    SuggestionNotFound
} from "styles/SearchSuggestion.styled";
import { FilterInput, FilterInputWrapper, FilterIcon } from "styles/filter/Search.styled";
import { SEARCH_CLASS_TYPE } from "constants/filter";
import { SuggestionItem, SuggestionItemName } from "styles/searchSuggestion/SearchSuggestionItem.styled";
import LoadingSpinner from "components/LoadingSpinner";
import { LoadingSpinnerDiv } from "styles/LoadingSpinner.styled";

const HubwiseMasterBuylistViewComponent: FunctionComponent<{
    buylistsInfo: {"Buylist Code": string, "Buylist Description": string}[];
    dataToDisplay: TemporaryMasterInstrumentsFundManagerType[];
    setDataToDisplay: React.Dispatch<React.SetStateAction<TemporaryMasterInstrumentsFundManagerType[]>>;
}> =  ({
    buylistsInfo,
    dataToDisplay,
    setDataToDisplay
}) => {
    const [query, setQuery] = useState("");
    const [somethingSearched, setSomethingSearched]= useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<{"Buylist Code": string, "Buylist Description": string}[] | []>([]);
    const [ loading, setLoading ]= useState<boolean>(false);
    const [buylistDataLoading, setBuylistDataLoading]= useState<boolean>(false);
    const [singleItemSelected, setSingleItemSelected]= useState<boolean>(false);
    const [selectedList, setSelectedList]= useState<{"Buylist Code": string, "Buylist Description": string}>();
    const [noOfFundsInList, setNoOfFundsInList]= useState<number>(0);
    const [filteredFunds, setFilteredFunds]= useState<TemporaryMasterInstrumentsFundManagerType[]>([]);

    const filterFundsData = (data: TemporaryMasterInstrumentsFundManagerType[], selectedList: {"Buylist Code": string, "Buylist Description": string}): Promise<TemporaryMasterInstrumentsFundManagerType[]> => {
        return new Promise((resolve) => {
            setBuylistDataLoading(true);
            setTimeout(()=>{
                const result: TemporaryMasterInstrumentsFundManagerType[]= [];

                data.forEach((fm: TemporaryMasterInstrumentsFundManagerType) => {
                    const filteredFunds: TemporaryFundsType[]= [];

                    fm.funds.forEach((fund: TemporaryFundsType) => {
                        fund.lists.forEach((list: {"Buylist Code": string, "Buylist Description": string}) => {
                            if (list["Buylist Code"] === selectedList["Buylist Code"] && list["Buylist Description"] === selectedList["Buylist Description"]){
                                filteredFunds.push(fund);
                            }
                        })
                    });

                    if(filteredFunds.length > 0) {
                        const filteredFM = {...fm, funds: filteredFunds};
                        result.push(filteredFM);
                    }
                })
                setBuylistDataLoading(false);
                resolve(result);
            }, 800);
        });
    }

    const filterBuylists = (data: {"Buylist Code": string, "Buylist Description": string}[], value: string): Promise<{"Buylist Code": string, "Buylist Description": string}[]> => {
        return new Promise((resolve) => {
            setTimeout(()=>{
                const result: {"Buylist Code": string, "Buylist Description": string}[]= [];

                data.forEach((list: {"Buylist Code": string, "Buylist Description": string}) => {
                    if (list["Buylist Code"].toLowerCase().includes(value) || list["Buylist Description"].toLowerCase().includes(value)) {
                        result.push(list);
                    }
                })
                resolve(result);
            }, 300);
        });
    }

    const handleClose = () => {
        setSingleItemSelected(false);
        setSelectedList(undefined);
        setNoOfFundsInList(0);
        setFilteredFunds([]);
    }

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setQuery(event.target.value);
    };

    const handleKeyUp = async (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && query.trim() !== "") {
            setSomethingSearched(true);
            setLoading(true);
            const result = await filterBuylists(buylistsInfo, query.toLowerCase());
            setSuggestions(result);
            setLoading(false);
        } else if (query.length === 0) {
            setSuggestions([]);
            setSomethingSearched(false);
            setLoading(false);
        }
    }; 

    const handleSearchSuggestionClick = async (list : {"Buylist Code": string, "Buylist Description": string}) => {
        setSingleItemSelected(true);
        setSelectedList(list);
        const newData: TemporaryMasterInstrumentsFundManagerType[]= await filterFundsData(dataToDisplay, list);
        setFilteredFunds(newData);
        let newNoOfFunds: number= noOfFundsInList;
        newData.map((fm: TemporaryMasterInstrumentsFundManagerType) => {
            newNoOfFunds += fm.funds.length;
        })
        setNoOfFundsInList(newNoOfFunds);
    }
    
    return !dataToDisplay ? <NoData /> : (
        singleItemSelected ? 
                <SelectedListHolder>
                    <CloseIconHolder>
                        <CloseIcon iconURL={closeIcon} onClick={handleClose} />
                    </CloseIconHolder>
                    <FundsTableContainer>
                        {buylistDataLoading ? (
                            <LoadingSpinnerDiv>
                                <LoadingSpinner />
                            </LoadingSpinnerDiv>
                            
                        ) :
                        <>
                            <DashboardSelectedList>
                                <DashboardItem>
                                    <StyledP>Selected Buylist: </StyledP>
                                    <StyledPValue>{selectedList?.["Buylist Code"]} - {selectedList?.["Buylist Description"]}</StyledPValue>
                                </DashboardItem>
                                <DashboardItem>
                                    <StyledP>Number of Funds : </StyledP>
                                    <StyledPValue>{noOfFundsInList}</StyledPValue>
                                </DashboardItem>
                                
                                
                            </DashboardSelectedList>
                            <RespTable>
                                <RespTableHeader >
                                    <RespTableRow>
                                        <TableHeaderCell>Fund</TableHeaderCell> 
                                        <TableHeaderCell>ISIN</TableHeaderCell> 
                                        <TableHeaderCell>SEDOL</TableHeaderCell> 
                                        <TableHeaderCell>FIGI</TableHeaderCell> 
                                    </RespTableRow>
                                </RespTableHeader>
                                {!(dataToDisplay?.length === 0 || !dataToDisplay) && 
                                    <RespTableBody>
                                        {filteredFunds.map((fm: TemporaryMasterInstrumentsFundManagerType, fmIndex: number) => 
                                        (
                                            <React.Fragment key={fmIndex}>
                                                {fm.funds.length > 0 && fm.funds.map((fund: TemporaryFundsType, fundIndex) => (
                                                    <RespTableRow key={fundIndex}>
                                                        {fund.figi_map.length > 0 ? <TableBodyCell>{fund.figi_map[0].name}</TableBodyCell> : 
                                                        <TableBodyCell>{fund["Instrument Name"]}</TableBodyCell>}
                                                        <TableBodyCell> {fund.ISIN}</TableBodyCell>
                                                        <TableBodyCell> {fund.Sedol} </TableBodyCell> 
                                                        {fund.figi_map.length > 0 && (
                                                            <TableBodyCell>{fund.figi_map[0].figi}</TableBodyCell> 
                                                        )}
                                                    </RespTableRow>
                                                ))}
                                                
                                            </React.Fragment>
                                            
                                        ))}
                                    </RespTableBody>
                                }
                            </RespTable>
                        </>
                        }
                    </FundsTableContainer>
                </SelectedListHolder> 
        : 
            <InstrumentSuggestions noMargin={true}>
                <FilterInputWrapper classType={SEARCH_CLASS_TYPE.DEFAULT}>
                    <FilterIcon icon="Search" />
                    <FilterInput
                        value={query}
                        onChange={handleChange}
                        placeholder="Search for a buylist..."
                        aria-label="input"
                        classType={SEARCH_CLASS_TYPE.DEFAULT}
                        onKeyUp={ handleKeyUp }
                    />
                </FilterInputWrapper>
                {suggestions.length > 0 && !loading && (                
                    suggestions.map((list, listIndex) => (
                        <div key={listIndex}>
                            <SuggestionItem onClick={()=>handleSearchSuggestionClick(list)}>
                                <SuggestionItemName>{list["Buylist Code"]} - {list["Buylist Description"]}</SuggestionItemName>
                            </SuggestionItem>
                        </div>
                    ))                
                )}
                {suggestions.length == 0 && !loading && somethingSearched && (
                    <SuggestionNotFound data-testid="suggestion-not-found">
                        Nothing found, try a different search
                    </SuggestionNotFound>
                )}
                {loading &&
                    <Loader data-testid="loader">
                        <LoaderBar />
                    </Loader>
                }
            </InstrumentSuggestions>
    )
};

export default HubwiseMasterBuylistViewComponent;