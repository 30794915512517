import styled from "styled-components";

export const Container = styled.div`
    width: 95%;
    padding: 40px;
`;

export const Content=styled.div`
    padding: 20px;
`;

export const Title= styled.div`
    font-weight: 700;
    font-size: 36px;
    line-height: 38px;
    color: #364073;
`;

export const TableHolder= styled.div`
    margin-top: 50px;
    width: 90%;
    overflow: auto;
`;

export const RequestDetailsHolder= styled.div`
    margin-top: 50px;
    width: 90%;
    overflow: auto;
    padding: 20px;
`;

export const BuylistInfoHolder= styled.div`
    margin-top: 40px;
`;

export const BuylistInfo= styled.div`
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: max-content;
`;

export const FieldValue= styled.div`
    font-size: 16px;
    line-height: 21.09px;
`;

export const FieldKey= styled.div`
    color: #364073;
    font-size: 18px;
    line-height: 21.09px;
`;

export const RequestDetailsTitle= styled.div`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #101828;
`;

export const RequestDetailsCloseIcon = styled.div<{ iconURL: string }>`
  top: 10px;
  right: 40px;
  width: 32px;
  height: 32px;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const WrapTable = styled.div`
  white-space: nowrap;
  display: block;
  overflow-x: scroll;
`;


export const RespTable = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
  // word-break: break-word;
`;

export const RespTableHeader = styled.div`
  display: table-header-group;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.PRIMARY[400]};
  background-color: rgba(54, 64, 115, 0.1);
  word-break: break-word; // this is a temporary fixd for table width
`;
export const TableHeaderCell = styled.div`
  display: table-cell;
  padding: 18px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
`;

export const RespTableBody = styled.div`
  display: table-row-group;
  padding: 0 40px;
`;

export const RespTableRow = styled.div<{ hasBorder?: boolean }>`
  display: table-row;
  border-bottom: ${(props) => (props.hasBorder ? "1px solid rgba(74, 130, 166, 0.5)" : "none")};
  font-size: 16px;
`;

export const TableBodyCell = styled.div`
  display: table-cell;
  padding: 18px;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 16px;
  &:first-child {
    padding-left: 40px !important;
  }
  &:last-child {
    padding-right: 40px !important;
  }
  color: ${({ theme }) => (theme.colors.WHITE !== "#FFFFFF" ? "#fff" : theme.colors.DARK[400])};
  & a {
    color: inherit;
    &:visited {
      color: inherit;
    }
    &:hover {
      color: #364073;
      opacity: 0.9;
    }
  }
`;

export const TableContentContainer = styled.div<{
    noShadow?: boolean;
    noMarginTop?: boolean;
  }>`
    display: block;
    column-span: all;
    overflow-x: auto;
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    margin-bottom: 60px;
    background-color: ${({ theme }) => theme.colors.WHITE};
    border-radius: 8px;
    padding: 12px 0;
    padding-bottom: 37px;
    white-space: nowrap;
    &:last-child {
      margin-bottom: 0px;
    }
    &:first-child {
      margin-top: ${({ noMarginTop }) => (noMarginTop ? "" : "20px")};
    }
  `;