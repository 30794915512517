import React, { FunctionComponent, useState, ChangeEventHandler, KeyboardEvent, ChangeEvent } from "react";
import {
    TemporaryFundsType,
    TemporaryFigiMapType
 } from "types/funds";
 import { 
    CloseIcon,
    FormRow,
    BuylistArea,
    BuylistsHolder,
    BuylistItem,
    BuylistCode,
    BuylistDescription
} from "styles/funds/MasterBuylist.styled";
import { FormLabel, InputWrapper, Input } from "styles/fundDetailsConfirmation/FundDetailsConfirmation.styled";
import BuylistsSelectionForm from "./BuylistsSelectionForm";
import { 
    closeIcon
} from "styles/funds/FundManagerT360.styled";

const SelectedFundForm : FunctionComponent<{
    selectedFund: {index: number; obj: TemporaryFundsType} | undefined;
    selectedFigi: {index: number; obj: TemporaryFigiMapType} | undefined;
    setSelectedFund: React.Dispatch<React.SetStateAction<{
        index: number;
        obj: TemporaryFundsType;
    } | undefined>>;
    buylistsInfo: {"Buylist Code": string, "Buylist Description": string}[];
    setSelectedFigi: React.Dispatch<React.SetStateAction<{
        index: number;
        obj: TemporaryFigiMapType;
    } | undefined>>;
    editEnabled: boolean
}> =  ({
    selectedFund,
    setSelectedFund,
    buylistsInfo,
    selectedFigi,
    setSelectedFigi,
    editEnabled
}) => {  

    const handleFigiChange = (label: string, value: string) => {
        const newFigi= selectedFigi?.obj;
        if(newFigi && selectedFigi) {
            newFigi[label]= value;
            setSelectedFigi({ index: selectedFigi.index, obj: newFigi });
        }
    };

    const handleFundChange = (label: string, value: string) => {
        const newfund= selectedFund?.obj;
        if(newfund && selectedFund) {
            newfund[label]= value;
            setSelectedFund({ index: selectedFund.index, obj: newfund });
        }
    };

    const handleListsChange = (listIndex: number) => {
        const filteredLists= selectedFund?.obj.lists.filter((item, index)=> index !== listIndex)
        if(filteredLists && selectedFund) {
            const newFund= { ...selectedFund };
            newFund.obj.lists= filteredLists;
            setSelectedFund(newFund);
        }
    };

    return (
        <>
            {selectedFigi? (
                <FormRow>
                    <FormLabel htmlFor={"figi"}>FIGI</FormLabel>
                    <InputWrapper>
                        <Input
                            type="text"
                            id={"figi"}
                            value={selectedFigi ? selectedFigi.obj.figi : "-"}
                            onChange={(e) => handleFigiChange("figi", e.target.value)}
                            disabled={!editEnabled}
                        />
                    </InputWrapper>
                    <FormLabel htmlFor={"name"}>Fund Name</FormLabel>
                    <InputWrapper>
                        <Input
                            type="text"
                            id={"name"}
                            value={selectedFigi ? selectedFigi.obj.name : "-"}
                            onChange={(e) => handleFigiChange("name", e.target.value)}
                            disabled={!editEnabled}
                        />
                    </InputWrapper>
                </FormRow>
            ): (
                <FormRow>
                    <FormLabel htmlFor={"figi"}>FIGI</FormLabel>
                    <InputWrapper>
                        <Input
                            type="text"
                            id={"figi"}
                            value={"-" || ""}
                            onChange={(e) => handleFigiChange("figi", e.target.value)}
                            disabled={!editEnabled}
                        />
                    </InputWrapper>
                    <FormLabel htmlFor={"name"}>Fund Name</FormLabel>
                    <InputWrapper>
                        <Input
                            type="text"
                            id={"name"}
                            value={selectedFund ? selectedFund.obj["Instrument Name"] : "-"}
                            onChange={(e) => handleFundChange("Instrument Name", e.target.value)}
                            disabled={!editEnabled}
                        />
                    </InputWrapper>  
                </FormRow>
            )}
            {selectedFund && (
                <>
                    <FormRow>
                        <FormLabel htmlFor={"ISIN"}>ISIN</FormLabel>
                        <InputWrapper>
                            <Input
                                type="text"
                                id={"ISIN"}
                                value={selectedFund? selectedFund.obj.ISIN : "-"}
                                onChange={(e) => handleFundChange("ISIN", e.target.value)}
                                disabled={!editEnabled}
                            />
                        </InputWrapper>
                        <FormLabel htmlFor={"Sedol"}>SEDOL</FormLabel>
                        <InputWrapper>
                            <Input
                                type="text"
                                id={"Sedol"}
                                value={selectedFund? selectedFund.obj.Sedol : "-"}
                                onChange={(e) => handleFundChange("Sedol", e.target.value)}
                                disabled={!editEnabled}
                            />
                        </InputWrapper>
                    </FormRow>
                    <FormRow>
                        <FormLabel htmlFor={"Instrument Type"}>Instrument Type</FormLabel>
                        <InputWrapper>
                            <Input
                                type="text"
                                id={"Instrument Type"}
                                value={selectedFund? selectedFund.obj["Instrument Type"] : "-"}
                                onChange={(e) => handleFundChange("Instrument Type", e.target.value)}
                                disabled={!editEnabled}
                            />
                        </InputWrapper>
                        <FormLabel htmlFor={"Exchange Code"}>Exchange Code</FormLabel>
                        <InputWrapper>
                            <Input
                                type="text"
                                id={"Exchange Code"}
                                value={selectedFund.obj["Exchange Code"] ? selectedFund.obj["Exchange Code"] : "-"}
                                onChange={(e) => handleFundChange("Exchange Code", e.target.value)}
                                disabled={!editEnabled}
                            />
                        </InputWrapper>
                    </FormRow>
                    <FormRow>
                        <FormLabel htmlFor={"Ringfenced"}>Ringfenced*</FormLabel>
                        <InputWrapper>
                            <Input
                                type="text"
                                id={"Ringfenced"}
                                value={selectedFund? selectedFund.obj.lists.some(item => item["Buylist Code"] === "BLST01" && item["Buylist Description"] === "Standard Buylist") ? "N" : "Y" : "-"}
                                disabled
                            />
                        </InputWrapper>
                    </FormRow> 
                    <BuylistArea>
                        <FormLabel>Buylists in which this Asset is available</FormLabel>
                        <BuylistsHolder>
                            {selectedFund.obj.lists.length > 0 && selectedFund.obj.lists.map((list, index) => (
                                <BuylistItem key={index}>
                                    <BuylistCode>{list["Buylist Code"]}</BuylistCode>
                                    <BuylistDescription>{list["Buylist Description"]}</BuylistDescription>
                                    {editEnabled && <CloseIcon iconURL={closeIcon} onClick={()=>handleListsChange(index)} />}
                                </BuylistItem>
                            ))}
                            
                        </BuylistsHolder>
                        {editEnabled && ( 
                            <BuylistsSelectionForm 
                                selectedFund={selectedFund}
                                setSelectedFund={setSelectedFund}
                                buylistsInfo={buylistsInfo}
                            />
                        )}
                    </BuylistArea>
                </>
            )}
        </>
        
    )
}

export default SelectedFundForm;