import { FunctionComponent, useEffect, useState } from "react";
import { 
  AssetOnboardingSummaryHolder,
  DropDownHolder
} from "styles/funds/ClientTemplateRequest.styled";
import AssetOnboardingSummaryCategory from "components/hubwise-dashboard/clientTemplateRequest/AssetOnboardingSummaryCategory";
import { FilterByCategory } from "components/filter/Index";
import useFilters from "hooks/useFilter";
import { InstrumentsManagerType } from "types/party";
import { FundType } from "types/funds";
import { Dispatch, SetStateAction } from "react";

const AssetOnboardingNavHolderComponent: FunctionComponent<{
    allFunds?: FundType[];
    filteredFunds?: FundType[] | object[];
    setFilteredFunds?: Dispatch<SetStateAction<FundType[] | object[]>>;
    allFMs?: InstrumentsManagerType[];
    filteredFMs?:  InstrumentsManagerType[];
    isMyView?: boolean
}> = ({
    allFunds,
    setFilteredFunds,
    allFMs,
    filteredFMs,
    isMyView,
    filteredFunds
}) => {
    const { secondSetOfFilteredData, setFilter } = useFilters(allFunds ? allFunds : [], allFunds);
    useEffect(()=>{
        if(setFilteredFunds) {
            setFilteredFunds(secondSetOfFilteredData);
        }
    },[secondSetOfFilteredData])

    return (
        <AssetOnboardingSummaryHolder>
            {!isMyView && 
                <DropDownHolder>
                    <FilterByCategory setFilter={setFilter} />
                </DropDownHolder>
            }            
            <AssetOnboardingSummaryCategory 
                fundManagers={filteredFMs && filteredFMs.length > 0 ? filteredFMs.length : allFMs?.length} 
                funds={filteredFunds && filteredFunds.length > 0 ? filteredFunds.length : allFunds?.length}
                isMyView = {isMyView}
                allFunds= {allFunds}
                setFilteredFunds= {setFilteredFunds}
            />  
            
        </AssetOnboardingSummaryHolder>
    );
  }
  
  export default AssetOnboardingNavHolderComponent;
  