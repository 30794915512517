import { FunctionComponent, useState } from "react";
import { 
    BottomContainer,
    BottomNavContainer,
    BottomNavItem,
    BottomCompContainer
} from "styles/funds/Onboarding.styled";
import ClientRequestsOnboardingTable from "./ClientRequestsOnboardingTable";
import { ONBOARDING_TABS, ONBOARDING_TAB } from "constants/hubwiseDashboard";
import FundDetailsConfirmationComponent from "components/detailsConfirmationForm/DetailsConfirmationForm";
import fundDataRequestMockTemplate from "../../../data/fundDataRequestMockTemplate.json";
import { ClientRequestTemplateType } from "types/party";
import { Accordion } from "components/party/accordion/Accordion";

const NavBox: FunctionComponent<{data: ClientRequestTemplateType[]}> =  ({data}) => {

    const [tab, setTab] = useState<ONBOARDING_TAB>(ONBOARDING_TAB.FUND_REQUEST);

    return (            
        <BottomContainer>
            <Accordion title="Fund Request" opened>
                <ClientRequestsOnboardingTable data={data}/>
            </Accordion>

            {/* <BottomCompContainer>
                {tab === ONBOARDING_TAB.FUND_REQUEST && <ClientRequestsOnboardingTable data={data}/>}  
                {tab === ONBOARDING_TAB.FUND_LINK && <FundDetailsConfirmationComponent data={fundDataRequestMockTemplate[0]} allFundsData={[]} title="Fund Data Request" hideProgress hideSubmit hideTitleSection hideNavSection/>}   
            </BottomCompContainer> */}
        </BottomContainer>
    )
};

export default NavBox;