import { FunctionComponent, useState } from "react";
import { 
    Container,
    Title,
    TableHolder,
    RequestDetailsHolder,
    RequestDetailsTitle,
    RequestDetailsCloseIcon,
    BuylistInfoHolder,
    BuylistInfo,
    FieldValue, 
    FieldKey,
    TableContentContainer,
    TableBodyCell,
    RespTableRow,
    RespTableBody,
    TableHeaderCell,
    RespTableHeader,
    RespTable,
    WrapTable
 } from "../../styles/hubwise/HubwiseClientManager.styled";
import requests from "../../data/freshDeskReqDummy.json";
import {
    closeIcon,
    CloseIconHolder
} from "styles/funds/FundManagerT360.styled";
import { FreshDeskReqTemporaryType } from "types/funds";
import { Accordion } from "components/party/accordion/Accordion";

const HubwiseClientManagerComponent: FunctionComponent =  () => {

    // TODO: get request data from storage

    const [singleSelectedRequest, setSingleSelectedRequest]= useState<boolean>(false);
    const [selectedReq, setSelectedReq]= useState<FreshDeskReqTemporaryType>();

    const handleClose = () => {
        setSingleSelectedRequest(false);
    }

    const handleReqClick = (req: FreshDeskReqTemporaryType) => {
        setSelectedReq(req);
        setSingleSelectedRequest(true);
    }
    
    return (
        <Container>
            <Title>Client Manager</Title>
            {!singleSelectedRequest ? 
            <Accordion title="Client Requests" opened noTopBorder>
                <TableHolder>
                    <WrapTable>
                        <RespTable>
                            <RespTableHeader>
                                <RespTableRow>
                                    <TableHeaderCell>Request ID</TableHeaderCell>
                                    <TableHeaderCell>Date</TableHeaderCell>
                                    <TableHeaderCell>Customer Name</TableHeaderCell>
                                    <TableHeaderCell>Status</TableHeaderCell>
                                </RespTableRow>
                                
                            </RespTableHeader>
                            <RespTableBody>
                                {requests && requests.map((request, index) => (
                                    <RespTableRow key={index} onClick={()=> handleReqClick(request)}>
                                        <TableBodyCell>{request.requestId}</TableBodyCell>
                                        <TableBodyCell>{request.creationDate.substring(0,10)}</TableBodyCell>
                                        <TableBodyCell>{request["Customer Name"]}</TableBodyCell>
                                        <TableBodyCell>{request.status}</TableBodyCell>
                                    </RespTableRow>
                                ))}
                            </RespTableBody>
                        </RespTable>
                    </WrapTable>
                    
                </TableHolder> 
                </Accordion>
            : 
                <RequestDetailsHolder>
                    <CloseIconHolder>
                        <RequestDetailsCloseIcon iconURL={closeIcon} onClick={handleClose} />
                    </CloseIconHolder>
                    <Accordion title="Requested Funds" opened noTopBorder>
                        <WrapTable>
                                <RespTable>
                                    <RespTableHeader>
                                        <RespTableRow>
                                            <TableHeaderCell>Fund</TableHeaderCell> 
                                            <TableHeaderCell>FIGI</TableHeaderCell> 
                                            {window.innerWidth > 500 && <TableHeaderCell>ISIN</TableHeaderCell> }
                                            {window.innerWidth > 600 && <TableHeaderCell>SEDOL</TableHeaderCell> }
                                            {/* hardcoded for demo on 27th Sep 2024 - TODO: to be updated */}
                                                {window.innerWidth > 769 && <TableHeaderCell>SS&C Permissioned*</TableHeaderCell> }
                                                {window.innerWidth > 1500 && <TableHeaderCell>RLB*</TableHeaderCell> }
                                                {window.innerWidth > 1500 && <TableHeaderCell>Transfer In*</TableHeaderCell> }
                                            {/* -- */}
                                        </RespTableRow>
                                    </RespTableHeader>
                                    <RespTableBody>
                                        {singleSelectedRequest && selectedReq && selectedReq.funds.map((fund, index) => (
                                            <RespTableRow key={index} >
                                                {fund.figi_map && fund.figi_map[0].figi ? <TableBodyCell>{fund.figi_map[0].name}</TableBodyCell> 
                                                : <TableBodyCell>{fund["Instrument Name"]}</TableBodyCell> }
                                                {fund.figi_map && fund.figi_map[0].figi ? <TableBodyCell>{fund.figi_map[0].figi}</TableBodyCell> 
                                                : <TableBodyCell>{"-"}</TableBodyCell> }
                                                <TableBodyCell> {fund.ISIN}</TableBodyCell>
                                                <TableBodyCell> {fund.Sedol} </TableBodyCell> 
                                                {/* hardcoded for demo on 27th Sep 2024 - TODO: to be updated */}
                                                    <TableBodyCell>{"Yes"}</TableBodyCell>
                                                    <TableBodyCell>{"No"}</TableBodyCell>
                                                    <TableBodyCell>{"No"}</TableBodyCell>
                                                {/* -- */}
                                            </RespTableRow>
                                        ))}
                                    </RespTableBody>
                                </RespTable>
                            </WrapTable>
                    </Accordion>
                    <Accordion title="Buylist" opened>
                        <BuylistInfoHolder>
                                <BuylistInfo>
                                    <FieldKey>{"Buylist Code: "}</FieldKey>
                                    <FieldValue>{selectedReq?.buylist["Buylist Code"]}</FieldValue>
                                    <FieldKey>{"Buylist Description: "}</FieldKey>
                                    <FieldValue>{selectedReq?.buylist["Buylist Description"]}</FieldValue>
                                </BuylistInfo>
                        </BuylistInfoHolder>
                    </Accordion>
                </RequestDetailsHolder>
            }
            
        </Container>
    )
};

export default HubwiseClientManagerComponent;