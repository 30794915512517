import React, { FunctionComponent, useState, ChangeEventHandler, KeyboardEvent, ChangeEvent } from "react";
import {
    TemporaryFundsType
 } from "types/funds";
import { BuylistsHolder } from "styles/funds/MasterBuylist.styled";
import {
    DropDownContainer,
    DropDownCover,
    DropDownItem,
    InputContainer,
    arrowDown,
    ArrowDownIcon
} from "styles/admin/SearchableDropdown.styled";
import { Input } from "styles/fundDetailsConfirmation/FundDetailsConfirmation.styled";

const BuylistsSelectionForm : FunctionComponent<{
    selectedFund: {index: number; obj: TemporaryFundsType} | undefined;
    setSelectedFund: React.Dispatch<React.SetStateAction<{
        index: number;
        obj: TemporaryFundsType;
    } | undefined>>;
    buylistsInfo: {"Buylist Code": string, "Buylist Description": string}[];
}> =  ({
    selectedFund,
    setSelectedFund,
    buylistsInfo
}) => {  

    const [selectedBuylistCode, setSelectedBuylistCode]= useState<string>("");
    const [selectedBuylistDescr, setSelectedBuylistDesc]= useState<string>("");
    const [showBuylistCodesOptions, setShowBuylistCodesOptions]= useState<boolean>(false);
    const [showBuylistDescrOptions, setShowBuylistDescrOptions]= useState<boolean>(false);
    const [buylistCodeSearchString, setBuylistCodeSearchString]= useState<string>("");
    const [buylistDescrSearchString, setBuylistDescrSearchString]= useState<string>("");
    const [filteredBuylistsInfo, setFilteredBuylistsInfo]= useState<{"Buylist Code": string, "Buylist Description": string}[]>([]);

    const handleOptionClick = (option : {"Buylist Code": string, "Buylist Description": string}) => {
        if(selectedFund) {
            const newFund= { ...selectedFund };
            if(newFund.obj.lists.some(item => item["Buylist Code"] === option["Buylist Code"] && item["Buylist Description"] === option["Buylist Description"])) {
                alert("This buylist already contains this fund");
            } else {
                newFund.obj.lists.push(option);
                setSelectedFund(newFund); 
                setShowBuylistCodesOptions(false);
                setShowBuylistDescrOptions(false);   
                setSelectedBuylistCode("");
                setSelectedBuylistDesc("");
                setBuylistCodeSearchString("");
                setBuylistDescrSearchString("");
                setFilteredBuylistsInfo(buylistsInfo);  
            }
        }
        setSelectedBuylistCode("");
        setSelectedBuylistDesc("");
        setBuylistCodeSearchString("");
        setBuylistDescrSearchString("");
    }

    const handleBuylistCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const searchString= e.target.value.toLowerCase();
        setBuylistCodeSearchString(searchString);
        setShowBuylistCodesOptions(true);

        if(searchString) {
            const filtered= buylistsInfo.filter((option) => option["Buylist Code"].toLowerCase().includes(searchString));
            setFilteredBuylistsInfo(filtered);
        } else {
            setFilteredBuylistsInfo(buylistsInfo);
        }
    }

    const handleBuylistDescrChange = (e: ChangeEvent<HTMLInputElement>) => {
        const searchString= e.target.value.toLowerCase();
        setBuylistDescrSearchString(searchString);
        setShowBuylistDescrOptions(true);

        if(searchString) {
            const filtered= buylistsInfo.filter((option) => option["Buylist Description"].toLowerCase().includes(searchString));
            setFilteredBuylistsInfo(filtered);
        } else {
            setFilteredBuylistsInfo(buylistsInfo);
        }
    }

    return (
        <BuylistsHolder>
            <DropDownContainer data-testid="codes-container">
                <InputContainer>
                    <Input 
                        type="text" 
                        value={selectedBuylistCode.trim() !== "" ? selectedBuylistCode : buylistCodeSearchString || ""}
                        onChange={(e)=>handleBuylistCodeChange(e)}
                        placeholder="Search Buylist Code"
                        data-testid="buylist-code-search-input"
                    />
                    <ArrowDownIcon src={arrowDown} onClick={()=>setShowBuylistCodesOptions(!showBuylistCodesOptions)} data-testid="arrow"/>
                </InputContainer>
                
                {showBuylistCodesOptions && (
                    <DropDownCover data-testid="list1">
                        {filteredBuylistsInfo.map((option, index) => (
                            <DropDownItem key={index} onClick={ () => handleOptionClick(option) } data-testid="option1">{option["Buylist Code"]}</DropDownItem>
                        ))}
                    </DropDownCover>
                )}
            </DropDownContainer>
            <DropDownContainer data-testid="descr-container">
                <InputContainer>
                    <Input 
                        type="text" 
                        value={selectedBuylistDescr.trim() !== "" ? selectedBuylistDescr : buylistDescrSearchString || ""}
                        onChange={(e)=>handleBuylistDescrChange(e)}
                        placeholder="Search Buylist Description"
                        data-testid="buylist-code-search-input"
                    />
                    <ArrowDownIcon src={arrowDown} onClick={()=>setShowBuylistDescrOptions(!showBuylistDescrOptions)} data-testid="arrow"/>
                </InputContainer>
                
                {showBuylistDescrOptions && (
                    <DropDownCover data-testid="list2">
                        {filteredBuylistsInfo.map((option, index) => (
                            <DropDownItem key={index} onClick={ () => handleOptionClick(option) } data-testid="option2">{option["Buylist Description"]}</DropDownItem>
                        ))}
                    </DropDownCover>
                )}
            </DropDownContainer>
        </BuylistsHolder>
    )
}

export default BuylistsSelectionForm;