import styled from "styled-components";
import { Link } from "react-router-dom";

export const MasterBuylistContainer = styled.div`
  height: 100%;
  padding: 32px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const BottomContainer= styled.div`
  width: 92%;
  margin-left: 10px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

export const FundManagersListContainer= styled.div`
   width: 100%;
  a {
    text-decoration: none;
  }
  p {
    color: ${({ theme }) => theme.colors.DARK[500]};
  }
  text-wrap: wrap;
`;

export const Title= styled.div`
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  font-weight: 700;
`;

export const TableContainer = styled.div`
  max-width: 100%;
  margin-top:30px;
  max-height: 55vh;
  overflow-y: auto;
`;

export const LinkToFundManagerProfile= styled(Link)`
  text-decoration: none;
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  margin-top: 20px;
`;

export const Thead = styled.thead`
  margin-bottom: 13px;
`;

export const Th = styled.th`
  font-size: 18px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  font-weight: 600;
  padding: 0 24px;
  padding-bottom: 12px;
  text-wrap: nowrap;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
`;

export const Tr = styled.tr<{ body?: boolean }>`
  text-align: center;
  height: ${(props) => (props.body === true ? "72px" : "")};
  cursor: ${(props) => (props.body === true ? "pointer" : "")};
`;

export const Td = styled.td<{ align?: string }>`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  line-height: 20px;
  padding: 0 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  max-width: 200px;
  width: min-content;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;
`;

export const FundNameTd= styled.td`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.GRAY[600]};
  line-height: 20px;
  padding: 0 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.GRAY[200]};
  text-align: left;
  text-wrap: nowrap;

`;

export const ExtraDetailsHeaderSection= styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkToFundManagerProfileHolder= styled.div`
  display: flex;
  flex-direction: row;
`;

export const FundManagerLinkTitle= styled.div`
  padding-right: 12px;
`;


export const InstrumentSuggestions = styled.div<{ noMargin?: boolean }>`
  overflow-y: auto;
  max-height: 350px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: ${({ theme }) => theme.colors.GREYED[800]};
  padding: ${({ noMargin }) => (noMargin ? "0" : "")};
  border-radius: ${({ noMargin }) => noMargin && "0 0 10px 10px"};

  /* scroll bar width */
  &::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* scroll bar track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px ${({ theme }) => theme.colors.DARK[400]};
    border-radius: 10px;
  }

  /* scroll bar handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.GREYED[80]};
    opacity: 0.6;
    border-radius: 10px;
  }

  /* scroll bar handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.GREYED[100]};
  }
`;

export const SelectedFundHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 97%;
    padding: 20px;
`;

export const SelectedListHolder= styled.div`
  width: 97%;
  padding: 20px;
`;

export const DashboardSelectedList= styled.div`
  margin-bottom: 50px;
  width: 40%;
`;

export const DashboardItem = styled.div`
  display: grid; 
  grid-template-columns: repeat(2, 1fr);
`;

export const StyledP= styled.p`
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.BLACK[600]};
  font-weight: 700;
  margin: 0;
  margin-top: 16px;
  color: #364073;
`;

export const StyledPValue= styled.p`
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.BLACK[600]};
  margin: 0;
  margin-top: 16px;
`;

export const FundsTableContainer= styled.div``;

export const CloseIcon = styled.div<{ iconURL: string }>`
  top: 10px;
  right: 40px;
  width: 32px;
  height: 32px;
  background-image: ${(props) => `url(${props.iconURL})`};
  background-size: 32px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const CloseIconHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const FormHolder = styled.form`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media (max-width: 769px) {
      grid-template-columns: repeat(1, 1fr);
    }
    flex-direction: column;
    margin-top: 40px;
`;

export const FormRow = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #EAECF0;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 769px) {
      flex-direction: column;
      align-items: start;
    }
`;

export const SubmitBtnHolder = styled.div`
  width: 100%;
  margin: 30px auto 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
`;

export const BuylistArea= styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 25px;
`;

export const BuylistsHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 98%;
  margin: auto;
  gap: 25px;
`;

export const BuylistItem= styled.div`
  display: grid;
  grid-template-columns: 20% 60% 1fr;
  height: 45px;
  overflow: auto;
`;

export const BuylistCode= styled.div``;
export const BuylistDescription= styled.div``;

export const NavContainer = styled.div`
  width: 60%;
  margin: 40px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  border: 1px solid #808080;
  border-radius: 8px;
  padding: 10px;
`;

export const NavItem = styled.div<{ isActive?: boolean}>`
  width: 100%;
  @media (max-width: 769px) {
    width: 100%;
  }
  font-size: 18px;
  text-align: center;
  font-weight: ${(props) => props.isActive ? "bold" : "400"};
  color: ${(props) => props.isActive ? "#4A82A6" : "#808080"};
  border-bottom-radius: 5px;
  cursor: pointer;
  text-wrap: wrap;
`;

export const AccordionHolder= styled.div`
  width: 100%;
`;

export const FundManagerInfoHolder= styled.div``;

export const FundsTableHolder= styled.div`
  margin-top:15px;
`;

export const FundsTableTitle= styled.div`
  color: #364073;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  width: 50%;
  padding: 10px;
  text-wrap: wrap;
  @media (max-width: 769px) {
    width: 95%;
  }
`;