import { FunctionComponent } from "react";
import { 
    Container,
    SearchHolder
} from "styles/funds/FundManagerT360.styled";
import BasicSearch from "components/searchComponents/BasicSearch";

const HubwiseFundManagerComponent: FunctionComponent =  () => {

    return (
        <Container>            
            <SearchHolder>
                <BasicSearch isHubwise />
            </SearchHolder> 
        </Container>
    )
};

export default HubwiseFundManagerComponent;